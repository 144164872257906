import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL= process.env.REACT_APP_API_URL;

export const fetchPayments =async () =>
    await axios.get(`${API_URL}/recouv/paymentmethod/` ,{
        headers: authHeader(),
    })

export const createPaymentCart =  async(payload) =>
    await axios.post(`${API_URL}/recouv/packcart/`,payload ,{
        headers: authHeader(),
    })

export const createPayment =  async(payload) =>
    await axios.post(`${API_URL}/recouv/subscription/`,payload ,{
        headers: authHeader(),
    })