import { BrowserRouter } from "react-router-dom";
import LSearchRoutes from "./routes";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { PersistGate } from "redux-persist/integration/react";
import StripeModal from "./layouts/Stripe/StripeModal";
import { DataProvider } from "./contexts/DataProvider";
const engine = new Styletron();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <DataProvider>
              <StripeModal />
              <BrowserRouter>
                <LSearchRoutes />
              </BrowserRouter>
              <ToastContainer />
            </DataProvider>
          </BaseProvider>
        </StyletronProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
