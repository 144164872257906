export const SEARCH = "/";
export const SEARCHS = "/search";
export const SEARCH_DETAIL = "/search/:rccm/detail/:denomination";
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register/:type";
export const DASHBOARD = "/dashboard";
export const SETTINGS = "/settings";
export const PROFILE = "/settings/profile";
export const PASSWORD = "/settings/password";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const VERIFICATION_OTP = "/auth/verification-otp/:type";
export const PAYMENTS = "/cart/payments";
export const CART = "/account-cart";
export const RESET_PASSWORD = "/password-reset-confirm/:uid/:token/";
export const LOGOUT = "/logout";
export const API_KEY = "/api-key";
export const LOG = "/log";
export const ALERTE = "/alertes";
export const LISTENOTIFICATION = "/alerte/liste-notification";
export const HISTORIQUEMODIFICATION = "/alerte/historque-modification";
export const FACTURATIONS = "/facturations";
export const ORGANISATIONS = "/organisations";

