import React, { useEffect, useState, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../store/modal/actions";
import { loadStripe } from "@stripe/stripe-js";
import { useData } from "../../contexts/DataProvider";
import { Api } from "../../api/services/Api";

const STRIPE_PK_LIVE = process.env.REACT_APP_STRIPE_PK_LIVE;
const stripePromise = loadStripe(STRIPE_PK_LIVE);

function StripeModal() {
  const useModal = useSelector((state) => state?.modal);
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");
  const [dataPaiements, setdataPaiements] = useState();

  const { data } = useData();

  useEffect(() => {
    if (!data || !useModal?.data) return;

    const { data: dataPaiement } = data;
    
    dataPaiement.currency = useModal.data.currency;
    console.log('dataPaiement',dataPaiement);
    setdataPaiements(dataPaiement);

    const getPaymentIntent = async () => {
      try {
        const paymentData = {
          montant: dataPaiement.amount,
          currency: dataPaiement.currency,
          description: "This is test payment",
          payment_data: JSON.stringify(dataPaiement),
        };

        const response = await Api.post(
          "recouv/payment-intent-stripe/",
          JSON.stringify(paymentData)
        );

        setClientSecret(response.data.payment_intent);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    getPaymentIntent();
  }, [data, useModal?.data]);

  const options = useMemo(
    () => ({
      appearance: { theme: "flat" },
      locale: "fr",
      clientSecret,
    }),
    [clientSecret]
  );

  return (
    <Modal
      onClose={() => dispatch(hideModal())}
      closeable
      isOpen={useModal?.isOpenStripePayment}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>
        <div className="tranch-confirm-header">
          <div className="tranch-confirm-header-box-title">
            <h5 className="tranch-confirm-header-title">Achat de document</h5>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              pays={useModal?.data?.pays}
              nextLink={useModal?.data?.nextLink}
              dataPaiement={dataPaiements}
            />
          </Elements>
        ) : (
          <p>Loading payment details...</p>
        )}
      </ModalBody>
    </Modal>
  );
}

export default StripeModal;
