// import { Utils } from "../utils";
// import { Auth } from "../utils/Auth";
import { getLocalState } from "../../utils/localStorage";
import authHeader from "./token.interceptor";
const token = getLocalState("access_token");

const URL = process.env.REACT_APP_API_URL;

const ROOT_PATH = "/api";

// const authHeader = new authHeader({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
//   Authorization: `Bearer ${authHeader}`,
// });

// const HEADERSS = new authHeader({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
// });

// const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

// const HEADERSOFFLINE = new authHeader({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
//   Authorization: `Bearer ${dataUser?.token}`,
// });

const FORMDATA_HEADERS = new Headers({
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});
const HEADERS = new Headers({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

const get = async (endpoint, signal = new AbortController().signal) => {
  try {
    const response = await fetch(`${URL}/${endpoint}`, {
      method: "GET",
      headers: HEADERS,
      signal,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response JSON
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const post = async (
  endpoint,
  payload ='',
  signal = new AbortController().signal
) => {
  // const header =
  //   authHeader !== null && authHeader !== undefined ? authHeader : HEADERSOFFLINE;

  try {
    const results = await fetch(`${URL}/${endpoint}`, {
      method: "post",
      body: payload,
      headers: HEADERS,
      signal,
    });
    // Check if the response is successful
    if (!results.ok) {
      throw new Error(`HTTP error! status: ${results.status}`);
    }
    // Parse the response JSON
    const data = await results.json();

    return {data, status: results.status};
  } catch (error) {
    console.error("Error data:", error);
  }
};

const postFormData = (
  endpoint,
  payload = "",
  signal = new AbortController().signal
) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "post",
      authHeader: FORMDATA_HEADERS,
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const put = async (endpoint, payload = "", signal = new AbortController().signal) => {
  try {
    const results = await fetch(`${URL}/${endpoint}`, {
      method: "put",
      body: payload,
      headers: HEADERS,
      signal,
    });
    // Check if the response is successful
    if (!results.ok) {
      throw new Error(`HTTP error! status: ${results.status}`);
    }
    // Parse the response JSON
    const data = await results.json();

    return {data, status: results.status};
  } catch (error) {
    console.error("Error data:", error);
  }
};

const erase = (endpoint, signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "delete",
      authHeader: authHeader,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const getResponseErrors = (response) => {
  return new Promise((resolve, reject) => {
    if (!response) reject(null);
    if (response.status === 401) {
      getLocalState("access_token");
      // window.location.replace('/connexion');
    }

    response.json().then((result) => {
      let errorMessages = [];

      errorMessages.push(result.message);

      for (let field in result) errorMessages.push(`${field} ${result[field]}`);

      resolve(errorMessages);
    });
  });
};

export const Api = {
  get,
  post,
  put,
  erase,
  postFormData,
};
