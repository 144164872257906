import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {setLocalState} from "../../utils/localStorage";
import * as navigations from "../../utils/navigation";
import {toast} from "react-toastify";

import * as _service from './service';

/**
 * @param loginPayload
 */
function* loginSaga(loginPayload) {
     const {payload,route,history}=loginPayload
    try {
        const response = yield call(_service.login, payload);
        yield put(constants.loginSuccess(response?.data));
        yield call(setLocalState, '_currentUser', response?.data?.user);
        yield call(setLocalState, 'access_token', response?.data?.access_token);
        yield call(setLocalState, 'refresh_token', response?.data?.refresh_token);
        yield call(history,route);
        yield call(toast.success,"Connexion réussir avec succès")
    } catch (error) {
        yield put(constants.loginFailed(error));
        yield call(toast.error,"Votre identifiant ou mot de passe est incorrect.")
    }
}

function* registerSaga(loginPayload) {
    const {payload,typeRoute,history}=loginPayload
    try {

        const response = yield call(_service.register, payload);


        if (response.status === 200 || response.status === 201) {
            yield put(constants.registerSuccess(response?.data));
            yield call(history,`/auth/verification-otp/${typeRoute}`);
            yield call(toast.success,"Un code OTP vous a été envoyée par mail.");
        } else {
            yield put(constants.registerFailed(response));
            yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");
        }

    } catch (error) {

        if(error?.response.data){
            if(error?.response?.data?.email){
                yield call(toast.error,error?.response?.data?.email[0])
            }
            if(error?.response?.data?.phone){
                yield call(toast.error,error?.response?.data?.phone[0])
            }

        }else{
            yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.")
        }
        yield put(constants.registerFailed(error));
    }
}

function* verificationOtpSaga(loginPayload) {
    const {payload,typeRoute,history}=loginPayload
    try {
       const response = yield call(_service.verificationOtp, payload);
        yield put(constants.verificationOtpSuccess(response?.data));

        if (response.status === 200 || response.status === 201) {

            yield call(setLocalState, '_currentUser', response?.data?.user);
            yield call(setLocalState, 'access_token', response?.data?.access_token);
            yield call(setLocalState, 'refresh_token', response?.data?.refresh_token);

            if(typeRoute==="cart"){
                yield call(history,navigations.PAYMENTS);
            }else{
                yield call(history,navigations.DASHBOARD);
            }

        } else {
// 
            console.log("response: ",response);

/*            yield put(constants.verificationOtpFailed(response));
            yield call(toast.error, "Une erreur s'est produite veuillez, contacter le service support.");*/


        }

        //yield call(history,navigations.LOGIN);
    } catch (error) {
        yield put(constants.verificationOtpFailed(error));
        yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.")
    }
}

function* forgotPasswordSaga(forgotPasswordPayload) {
    const {payload,history}=forgotPasswordPayload
    try {
        const response = yield call(_service.forgotPassword, payload);
        if (response.status === 200 || response.status === 201) {
            yield put(constants.forgotPasswordSuccess(response?.data));
            yield call(history,navigations.LOGIN);
            yield call(toast.success,"Un mail de réinitialisation vous a été envoyé par mail.");
        } else {
            yield put(constants.forgotPasswordFailed(response));
            yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.");
        }

    } catch (error) {
        yield put(constants.forgotPasswordFailed(error));
        yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.");
    }
}

function* resetPasswordSaga(resetPasswordPayload) {
    const {payload,resetForm,history}=resetPasswordPayload
    try {
        const response = yield call(_service.resetPassword, payload);

        if (response.status === 200 || response.status === 201) {
            yield put(constants.resetPasswordSuccess(response?.data));
            resetForm();
            yield call(history,navigations.LOGIN);
            yield call(toast.success,"Le mot de passe a été réinitialisé.");
        } else {
            yield put(constants.resetPasswordFailed(response));
            yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.");
        }

    } catch (error) {
        yield put(constants.resetPasswordFailed(error));
        yield call(toast.error,"Une erreur s'est produite.Veuillez réessayer ou contacter le service client si l'erreur persiste.")
    }
}

export default function* authSaga() {
    yield takeEvery(Actions.LOGIN_REQUESTING, loginSaga);
    yield takeEvery(Actions.REGISTER_REQUESTING, registerSaga);
    yield takeEvery(Actions.VERIFICATION_OTP_REQUESTING, verificationOtpSaga);
    yield takeEvery(Actions.FORGOT_PASSWORD_REQUESTING, forgotPasswordSaga);
    yield takeEvery(Actions.RESET_PASSWORD_REQUESTING, resetPasswordSaga);
}
